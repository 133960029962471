import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Button from "../Button";
import Card from "../Card";

import Professionalitaet from "../../assets/desired-icons/Professionalitaet.png";
import Kundenbetreuung from "../../assets/desired-icons/Kundenbetreuung.png";
import Ergespraech from "../../assets/desired-icons/Ergespraech.png";

import "./ServicePreview.scss";

const ServicePreview = () => {
  const navigate = useNavigate();
const { t } = useTranslation();

const handleClick = () => {
  navigate('/service', { state: { sectionId: 'sectionId' } });
};



const breifServices = [
  {
    id: "1",
    title: "short-title-1",
    image: Professionalitaet,
    description: "short-text-1",
  },
  {
    id: "2",
    title: "short-title-2",
    image: Kundenbetreuung,
    description: "short-text-2",
  },
  {
    id: "3",
    title: "short-title-3",
    image: Ergespraech,
    description: "short-text-3",
  },
];



return (
    <div className="service-preview-container">
        <div className="header-container center">
        <h1 className="green-title">{t('services-intro').toLocaleUpperCase()}</h1>
          <h2 className="black-title">{t('services-title')}</h2>
          <p className="p-3">{t('services-description')}</p>
        </div>
        <div className="service-card-container">
        {breifServices.map((service) => (
            <Card key={service.id} icon={service.image} title={service.title} description={service.description} />
          )) }
          </div>
        <div className="service-preview-button pb-2 mb-2">
        <Button onClick={handleClick}>{t('view-services')}</Button>
        </div>
        <br />
        <br />
        <br />
    </div>
)
};

export default ServicePreview;