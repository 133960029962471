import React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import './Navigation.scss';

const FooterNavigation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigation = (path, sectionId, event) => {
    event.preventDefault();
    navigate(path);

    setTimeout(() => {
      if (sectionId) {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        window.scrollTo(0, 0);
      }
    }, 0);
  };

  return (
    <div className="navigation-section">
      <div className="nav-item">
        <ul>
          <li>
            <a href="/">{t("home").toUpperCase()}</a>
          </li>
          <li>
            <a href="/about" onClick={(event) => handleNavigation('/about', 'about-section', event)}>{t("about").toUpperCase()}</a>
          </li>
          <li>
            <a href="/service" onClick={(event) => handleNavigation('/service', 'service-section', event)}>{t("services").toUpperCase()}</a>
          </li>
          <li>
            <a href="/faq" onClick={(event) => handleNavigation('/faq', 'faq-section', event)}>FAQ</a>
          </li>
        </ul>
      </div>
      <div className="nav-item">
        <ul>
          <li>
            <a href="/impressum" onClick={(event) => handleNavigation('/impressum', 'impressum-section', event)}>{t("imprint").toUpperCase()}</a>
          </li>
          <li>
            <a href="/datenschutz" onClick={(event) => handleNavigation('/datenschutz', 'datenschutz-section', event)}>{t("privacy").toUpperCase()}</a>
          </li>
          <li>
            <a href="/contact" onClick={(event) => handleNavigation('/contact', 'contact-section', event)}>{t("contact").toUpperCase()}</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FooterNavigation;
