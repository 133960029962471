import React from 'react';
import { useTranslation } from 'react-i18next';


import Professionalitaet from "../../assets/desired-icons/Professionalitaet.png";
import Community from "../../assets/desired-icons/Steigere.png";
import Ergespraech from "../../assets/desired-icons/Ergespraech.png";
import Kundenbetreuung from "../../assets/desired-icons/Kundenbetreuung.png";
import fire from "../../assets/desired-icons/fire.png";
import Content from "../../assets/desired-icons/Content.png";
import heart from "../../assets/desired-icons/heart.png";
import Maximiere from "../../assets/desired-icons/Maximiere.png";

import Card from '../Card';
import Divider from '../Divider';

import './Services.scss';

const ServiceSection = () => {
  const { t } = useTranslation();


 const allServices = [
  {
    id: "1",
    title: "short-title-1",
    image: Professionalitaet,
    description: "short-text-1",
  },
  {
    id: "2",
    title: "short-title-2",
    image: Kundenbetreuung,
    description: "short-text-2",
  },
  {
    id: "3",
    title: "short-title-3",
    image: Ergespraech,
    description: "short-text-3",
  },
  {
    id: "4",
    title: "short-title-4",
    image: Kundenbetreuung,
    description: "short-text-4",
  },
  {
    id: "5",
    title: "short-title-5",
    image: Community,
    description: "short-text-5",
  },
  {
    id: "6",
    title: "short-title-6",
    image: fire,
    description: "short-text-6",
  },
  {
    id: "7",
    image: Content,
    title: "short-title-7",
    description: "short-text-7",
  },
  {
    id: "8",
    image: heart,
    title: "short-title-8",
    description: "short-text-8",
  },
  {
    id: "9",
    image: Maximiere,
    title: "short-title-9",
    description: "short-text-9",
  },
];

  return (
    <div className="service-container" id="service-section">
      <Divider bgColor='#fff' textColor='#000' title={t('what-we-do').toLocaleUpperCase()} showIcon/>
      <div className="service-content">
            {allServices.map((service, index) => (
              <Card
              key={index}
              title={service.title}
              description={service.description}
              icon={service.image}
            />
            ))}
          </div>
    </div>
  );
};

export default ServiceSection;
