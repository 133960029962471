import React from "react";

import "./NextSteps.scss";
import phoneIcon from "../../assets/desired-icons/Ergespraech.png";
import chatIcon from "../../assets/desired-icons/chat.png";
import contractIcon from "../../assets/desired-icons/Contract.png";
import startIcon from "../../assets/desired-icons/Steigere.png";

const NextSteps = () => {
  return (
    <section className="sc--main">
      <div className="ct--main w-container">
        <div className="cmp--text center">
          <p className="h--m txt--green">The Next Steps</p>
          <h2 className="h--l">What's the plan ahead?</h2>
        </div>
        <div className="cmp--ic"  id="contact-section">
          <div className="cmp--step">
            <div className="cmp--stepicon">
              <img src={phoneIcon} loading="lazy" alt="phone icon" className="img--stepico" />
            </div>
            <h2 className="h--s txt--green">Introduction</h2>
            <p>After you've scheduled your appointment, one of our managers will contact you at the agreed-upon time. Together, we'll determine if collaboration is conceivable for both parties.</p>
          </div>
          <div className="cmp--arrow">
            <i className="fa-solid fa-arrow-right"></i>
          </div>
          <div className="cmp--step">
            <div className="cmp--stepicon">
              <img src={chatIcon} loading="lazy" alt="chat icon" className="img--stepico _2" />
            </div>
            <h2 className="h--s txt--green">Zoom</h2>
            <p>The second step involves a joint Zoom conversation. The goal of this discussion is to delve into further details of a potential collaboration and to get to know each other better.</p>
          </div>
          <div className="cmp--arrow">
            <i className="fa-solid fa-arrow-right"></i>
          </div>
          <div className="cmp--step">
            <div className="cmp--stepicon">
              <img src={contractIcon} loading="lazy" alt="contract icon" className="img--stepico _1" />
            </div>
            <h2 className="h--s txt--green">Contract</h2>
            <p>Should we jointly decide on collaboration, the next focus is on contract specifics. We'll cover the costs of creating a contract tailored entirely to your individual desires and visions.</p>
          </div>
          <div className="cmp--arrow">
            <i className="fa-solid fa-arrow-right"></i>
          </div>
          <div className="cmp--step">
            <div className="cmp--stepicon">
              <img src={startIcon} loading="lazy" alt="start icon" className="img--stepico" />
            </div>
            <h2 className="h--s txt--green">Kick-off</h2>
            <p>The foundation for a successful partnership is now laid. Together, we embark on the journey to elevate you to the top 1% of creators on OnlyFans.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NextSteps;
