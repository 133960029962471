import React from "react";
import { useTranslation } from "react-i18next";

import Topimage from "../components/Topimage";

const SectionOneSubpages = () => {
  const { t } = useTranslation();
  return (
    <>
      <Topimage />
    </>
  );
};

export default SectionOneSubpages;
