import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";

import sneek from "../../assets/images/sneek.webp";
import Button from "../Button";
import Heading from "../Heading";
import Icon from "../Icon";

import "./About.scss";

const useIntersectionObserver = (options) => {
  const ref = useRef();
  const [isVisible, setIsVisible] = React.useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    const currentRef = ref.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [options]);

  return [ref, isVisible];
};

const AboutBrief = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isVisible1] = useIntersectionObserver({ threshold: 0.1 });
  const [ref2, isVisible2] = useIntersectionObserver({ threshold: 0.1 });
  const [ref4, isVisible4] = useIntersectionObserver({ threshold: 0.1 });
  const [ref5, isVisible5] = useIntersectionObserver({ threshold: 0.1 });
  const imageSectionRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      if (imageSectionRef.current) {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const offset = imageSectionRef.current.offsetTop;
        const height = imageSectionRef.current.clientHeight;
        if (scrollTop + window.innerHeight > offset && scrollTop < offset + height) {
          imageSectionRef.current.style.backgroundPositionY = `${(scrollTop - offset) * 0.5}px`;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = () => {
    navigate('/about', { state: { sectionId: 'sectionId' } });
  };

  return (
    <div className="about-section">
      <div className="about-container">
        <div className="text-section">
          <Icon color="#ff9ec8" position="left" />
          <br />
          <br />
          <div className="txt--about">
            <Heading level="3" className={`txt--abouttitle ${isVisible1 ? "visible" : ""}`}>
              {t("about").toLocaleUpperCase()}
            </Heading>
            <p className={`txt--aboutmini ${isVisible2 ? "visible" : ""}`} ref={ref2}>
          {t("about-text-1")}
          </p>
          <p className={`txt--aboutmini ${isVisible4 ? "visible" : ""} hide-mobile`} ref={ref4}>
          {t("about-text-2")}
          </p>
            <p className={`txt--aboutmini ${isVisible4 ? "visible" : ""}`} ref={ref4}>
              {t("about-text-4")}
            </p>
          <Button onClick={handleClick}>{t("more-info")}</Button>
          </div>
        </div>
        <div className="image-section parallax" id="sectionId" ref={imageSectionRef}>
          <img
            src={sneek}
            loading="lazy"
            width="339"
            alt=""
            className={`img-logo-miniabout txt--aboutmini ${isVisible5 ? "visible" : ""}`}
            ref={ref5}
          />
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default AboutBrief;
