import React from "react";
import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";

import About from "./components/About";
import AboutUs from "./components/AboutUs";
import Carousel from "./components/Carousel";
import Consent from "./components/Consent";
import Contact from "./components/Contact";
import Divider from "./components/Divider";
import Footer from "./components/Footer";
import Impressum from "./components/Impressum";
import Datenschutz from "./components/Datenschutz";
import NavBar from "./components/Navbar";
import NextSteps from "./components/NextSteps";
import ScrollToTop from "./components/ScrollToTop";
import ServiceSection from "./components/Services";
import FAQ from "./components/FAQ";
import ServicePreview from "./components/ServicePreview";
import SectionOne from "./sections/section-one";
import SectionOneSubpages from "./sections/section-one-subpages";

import "./App.scss";

const App = () => {
  const { t } = useTranslation();

  return (
    <div className="App">
      <Consent />
      <NavBar />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <SectionOne />
              <About />
              <Divider
                title={t("client-title").toLocaleUpperCase()}
                subtitle={t("we-help-1").toLocaleUpperCase()}
                showIcon
              />
              <br />
              <br />
              <ServicePreview />
              <Carousel />
              <Contact />
            </>
          }
        />
        <Route
          path="about"
          element={
            <>
              <SectionOneSubpages />
              <AboutUs />
              <Divider title={t("client-title").toLocaleUpperCase()} showIcon />
              <br />
              <br />
              <Contact />
            </>
          }
        />
        <Route
          path="service"
          element={
            <>
              <SectionOneSubpages />
              <ServiceSection />
              <Divider
                title={t("client-title").toLocaleUpperCase()}
                subtitle={t("we-help-1").toLocaleUpperCase()}
              />
              <Contact />
            </>
          }
        />
        <Route
          path="faq"
          element={
            <>
              <SectionOneSubpages />
              <FAQ />
              <Divider
                title={t("client-title").toLocaleUpperCase()}
                subtitle={t("we-help-1").toLocaleUpperCase()}
              />
              <Contact />
            </>
          }
        />
        <Route
          path="contact"
          element={
            <>
              <SectionOneSubpages />
              <NextSteps />
              <Divider showIcon />
              <Contact />
            </>
          }
        />
        <Route
          path="impressum"
          element={
            <>
              <SectionOneSubpages />
              <Impressum />
              <Divider
                title={t("client-title").toLocaleUpperCase()}
                subtitle={t("we-help-1").toLocaleUpperCase()}
              />
              <Contact />
            </>
          }
        />
        <Route
          path="datenschutz"
          element={
            <>
              <SectionOneSubpages />
              <Datenschutz />
              <Divider
                title={t("client-title").toLocaleUpperCase()}
                subtitle={t("we-help-1").toLocaleUpperCase()}
              />
              <Contact />
            </>
          }
        />
      </Routes>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default App;
