import React from 'react';
import { useTranslation } from 'react-i18next';

import ContactForm from '../../components/ContactForm';
import FAQ from '../../components/FAQ';

import './Contact.scss';

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div className="contact-container" id='contact-section'>
      {/* <NextSteps /> */}
      <br />
      <br />
      {/* <div className="icon" /> */}
      <div className="contact-title-text">
      <h1>{t('contact-us')}</h1>
      <p>{t('contact-text')}</p>
      </div>
      <div className="contact-section">
        <div className="contact-form-wrapper">
          <p>{t("drop-message")}</p>
          <ContactForm />
        </div>
        <div className="faq-wrapper">
          <FAQ />
        </div>
      </div>
    </div>
  );
};

export default Contact;
